.back_to_home_button {
  margin: 10px 0;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid #007bff;
}

.back_to_home_button i {
  font-size: 16pt;
}

.btn-with-border-radius {
  margin: 10px 0;
  border-radius: 25px;
}

.simple-ul ul {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
  font-size: 9pt;
}

.simple-ul ul li {
  margin: 0;
  padding: 0;
}

.fos_user_content_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  background: #f9f9f9;
}

.fos_user_content_wrapper .fos_user_content {
  display: block;
  min-width: 300px;
  max-width: 400px;
}

