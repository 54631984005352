@import "_variables";
/**
 * Sticky navbar to show navbar on the top after user scrolls down.
 *
 * Based on: https://gist.github.com/sarvar/79ecdc7c97bbdc9d6debf94fc19e57cb
 */
.sticky.is-sticky {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
}