@import "_variables";
$siteBlack: #181818;
$coverGradient: rgba(24, 24, 24, 0.5);
$blue: #00587c;

.btn {
    border-radius: 0;
}

.btn-primary {
    border-color: $blue;
    background-color: $blue;

    &:hover {
        border-color: $blue;
        background-color: $blue;
    }
}

h2 {
    margin-top: 40px;

    @media (max-width: 575.98px) {
        & {
            font-size: 1.5rem;
        }
    }
}

section {
    padding: 80px 0;
}

section .title {
    text-align: center;
    margin-bottom: 50px;
    text-transform: uppercase;
}

.scroll-arrow {
    margin-top: 80px;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: #fff;
    letter-spacing: .1em;
    text-decoration: none;
    transition: opacity .3s;

    span {
        position: absolute;
        top: 0;
        left: 50%;
        width: 46px;
        height: 46px;
        margin-left: -23px;
        border: 1px solid #fff;
        border-radius: 100%;
        box-sizing: border-box;

        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            content: '';
            width: 16px;
            height: 16px;
            margin: -12px 0 0 -8px;
            border-left: 1px solid #fff;
            border-bottom: 1px solid #fff;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            box-sizing: border-box;
        }
    }
}

#cover {
    width: 100%;
    min-height: 700px;
    height: 100vh;
    background-image: linear-gradient(0deg, $coverGradient, $coverGradient), url("./images/background.jpg");
    background-size: cover;

    .cover-container {
        text-align: center;
        padding: 60px 0;
        color: #fff;

        .lead {
            padding-top: 20px;
            font-size: 1.4rem;
        }
    }
}

#navigation {
    background: #fff;

    .navbar-toggler-right {
        float: left;
    }

    .navbar-brand {
        font-size: 1.4rem;
        color: $siteBlack;

        #logo {
            transition: all 0.3s;
            width: 100px;
            margin: 16px;
        }
    }

    .nav-link {
        font-size: 1.2rem;
        color: $siteBlack;
    }

    &.shrinked {
        padding: 0 16px;

        .nav-link {
            font-size: 1rem;
        }

        .navbar-brand {
            #logo {
                width: 60px;
            }
        }
    }

}

#facilities {
    background: #0F2027;
    background: -webkit-linear-gradient(to bottom, #2C5364, #203A43, #0F2027); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #fff;

    .card {
        background: transparent;
    }
}

#services {
    background: #fff;

    .card {
        &:hover {
            background: #fff;
            color: $siteBlack;
            border: 1px solid #D8D8D8;
            box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
        }

        &:hover .card-block:before {
            transform: rotate(360deg);
            transition: .5s all ease;
        }
    }
}

#facilities, #services {
    .card {
        padding: 1rem !important;
        border: none;
        border-color: #D8D8D8;
        margin-bottom: 1rem;
        transition: .5s all ease;
        text-align: center;
        border-radius: 0;

        .card-block {
            position: relative;
        }

        .card-icon {
            color: $siteBlack;
        }

        .card-title {
            padding-top: 1rem;
        }
    }
}

#contactus {
    background: #0F2027;
    color: #fff;

    p {
        margin-top: 1rem;
    }
}

footer {
    padding: 20px;
    min-height: 40px;

    a {
        color: $blue;
        margin-left: 10px;
    }
}

#privacy_policy_section {
    max-width: 1024px;
    margin: 0 auto;
}

#swal2-content {
    text-align: left !important;

    h2 {
        margin-bottom: 20px;
    }

    ul, ol {
        margin: 20px 0;

        li {
            margin: 10px 0;
        }
    }
}