.btn {
  border-radius: 0;
}

.btn-primary {
  border-color: #00587c;
  background-color: #00587c;
}

.btn-primary:hover {
  border-color: #00587c;
  background-color: #00587c;
}

h2 {
  margin-top: 40px;
}

@media (max-width: 575.98px) {
  h2 {
    font-size: 1.5rem;
  }
}

section {
  padding: 80px 0;
}

section .title {
  text-align: center;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.scroll-arrow {
  margin-top: 80px;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
}

.scroll-arrow span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 46px;
  height: 46px;
  margin-left: -23px;
  border: 1px solid #fff;
  border-radius: 100%;
  box-sizing: border-box;
}

.scroll-arrow span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: '';
  width: 16px;
  height: 16px;
  margin: -12px 0 0 -8px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
}

#cover {
  width: 100%;
  min-height: 700px;
  height: 100vh;
  background-image: linear-gradient(0deg, rgba(24, 24, 24, 0.5), rgba(24, 24, 24, 0.5)), url("../../public/assets/images/background.jpg");
  background-size: cover;
}

#cover .cover-container {
  text-align: center;
  padding: 60px 0;
  color: #fff;
}

#cover .cover-container .lead {
  padding-top: 20px;
  font-size: 1.4rem;
}

#navigation {
  background: #fff;
}

#navigation .navbar-toggler-right {
  float: left;
}

#navigation .navbar-brand {
  font-size: 1.4rem;
  color: #181818;
}

#navigation .navbar-brand #logo {
  transition: all 0.3s;
  width: 100px;
  margin: 16px;
}

#navigation .nav-link {
  font-size: 1.2rem;
  color: #181818;
}

#navigation.shrinked {
  padding: 0 16px;
}

#navigation.shrinked .nav-link {
  font-size: 1rem;
}

#navigation.shrinked .navbar-brand #logo {
  width: 60px;
}

#facilities {
  background: #0F2027;
  background: -webkit-linear-gradient(to bottom, #2C5364, #203A43, #0F2027);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #2C5364, #203A43, #0F2027);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fff;
}

#facilities .card {
  background: transparent;
}

#services {
  background: #fff;
}

#services .card:hover {
  background: #fff;
  color: #181818;
  border: 1px solid #D8D8D8;
  box-shadow: 5px 7px 9px -4px #9e9e9e;
}

#services .card:hover .card-block:before {
  transform: rotate(360deg);
  transition: .5s all ease;
}

#facilities .card,
#services .card {
  padding: 1rem !important;
  border: none;
  border-color: #D8D8D8;
  margin-bottom: 1rem;
  transition: .5s all ease;
  text-align: center;
  border-radius: 0;
}

#facilities .card .card-block,
#services .card .card-block {
  position: relative;
}

#facilities .card .card-icon,
#services .card .card-icon {
  color: #181818;
}

#facilities .card .card-title,
#services .card .card-title {
  padding-top: 1rem;
}

#contactus {
  background: #0F2027;
  color: #fff;
}

#contactus p {
  margin-top: 1rem;
}

footer {
  padding: 20px;
  min-height: 40px;
}

footer a {
  color: #00587c;
  margin-left: 10px;
}

#privacy_policy_section {
  max-width: 1024px;
  margin: 0 auto;
}

#swal2-content {
  text-align: left !important;
}

#swal2-content h2 {
  margin-bottom: 20px;
}

#swal2-content ul,
#swal2-content ol {
  margin: 20px 0;
}

#swal2-content ul li,
#swal2-content ol li {
  margin: 10px 0;
}

